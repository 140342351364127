<template>
  <div class="upda fx">
    <div class="col" v-if="imglisted.length != 0" style="">
      <div
        v-for="(item, index) in imglisted"
        :key="index"
        style="display: inline-block; margin-right: 10px"
      >
        <div class="img-hover img_div">
          <img
            :src="nourl ? item : item.url"
            style="width: 70px; height: 70px"
            class="imgCSSS"
          />
          <div class="mask">
            <div
              v-show="!disabled"
              class="el-icon-close click_fly"
              @click="handleRemove(index)"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <el-upload
      ref="upload"
      accept="image/*"
      :before-upload="handleBeforeUpload"
      v-if="imglisted.length != imgnumber && !loading"
      :disabled="disabled"
      :action="javabaseURL + '/ptuser/common/upload'"
      list-type="picture-card"
      name="file"
      :headers="token"
      :auto-upload="true"
      :show-file-list="false"
      :on-progress="handleExceed"
      :on-success="
        (response, file) => {
          onSuccess(response, file);
        }
      "
    >
      <i slot="default" class="el-icon-plus" />
    </el-upload>
    <div
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      class="loadse"
      v-if="loading"
    ></div>
    <!-- 图片放大弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      custom-class="el-dialog-s"
      append-to-body
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "UpimgList",
  props: {
    // 最多上传照片数量
    imgnumber: {
      type: Number,
      default: null,
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: null,
    },
    //没有key
    nourl: {
      type: Boolean,
      default: false,
    },
    //图片回显列表
    imglist: {
      type: Array,
      default() {
        return [];
      },
    },
    //限制上传图片大小
    imageSize: {
      type: Number,
      default: 3,
    },
  },
  computed: {},
  data() {
    return {
      loading: false,
      javabaseURL: window.localStorage.getItem("javabaseURL"),
      token: { token: localStorage.getItem("token") },
      // 图片放大显示
      dialogImageUrl: "",
      // 图片放大显示弹窗
      dialogVisible: false,
      imglisted: this.imglist,
    };
  },
  watch: {
    imglist() {
      this.imglisted = this.imglist;
    },
  },
  methods: {
    handleExceed(file) {
      if (file.loaded > 1000000 * this.imageSize) {
        // this.$message.error(`上传图片最大不能超过${this.imageSize}MB`);
        this.$refs.upload.abort();
      }
    },
    // 图片下载回显
    onSuccess(response, file) {
      this.loading = false;
      if (this.nourl) {
        this.imglisted.push(response.content.url);
      } else {
        this.imglisted.push({ url: response.content.url });
      }
      console.log(this.imglist);
      // this.$emit("deleUpimges", this.imglisted);
      this.$emit("changeimge");
    },
    //删除图片
    handleRemove(index) {
      this.imglisted.splice(index, 1);
      this.$emit("changeimge");
      // this.$emit("deleUpimg", index);
    },
    //放大图片
    handlePreview(i) {
      this.dialogImageUrl = this.imglisted[i].url;
      if (this.dialogImageUrl == undefined) {
        this.dialogImageUrl = this.imglisted[i];
      }
      this.dialogVisible = true;
    },
    // 判断上传的是否为图片
    handleBeforeUpload(file) {
      if (file.size > 1000000 * this.imageSize) {
        this.$message.error(`上传图片最大不能超过${this.imageSize}MB`);
        return false;
      }
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img.toLowerCase() === "jpg";
      const suffix2 = img.toLowerCase() === "png";
      const suffix3 = img.toLowerCase() === "jpeg";
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error("上传图片仅支持JPG,PNG,JPEG图片格式！");
        return false;
      }
      this.loading = true;
      // return suffix || suffix2 || suffix3;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 70px;
  height: 70px;
}
::v-deep .el-upload--picture-card {
  width: 70px;
  height: 70px;
  line-height: 70px;
}
::v-deep .el-loading-spinner .path {
  stroke: #1abc9c;
}
.mask {
  position: absolute;
  background: rgba(101, 101, 101, 0.6);
  color: #ffffff;
  opacity: 0;
  top: 0;
  right: 0;
  width: 25px;
  height: 20px;
  border-radius: 5px 0px 5px 0px;
  // pointer-events: none;
  div {
    display: flex;
    justify-content: center;
    line-height: 20px;
  }
}

.img_div {
  border-radius: 10px;
  display: block;
  position: relative;
  .mask {
    opacity: 1;
  }
}
.loadse {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  border: 1px dashed #c0ccda;
}
</style>
