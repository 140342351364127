<template>
  <div>
    <div v-if="isWeChat&&!isIOS" class="back-foot">
        <img src="@/assets/images/back-foot-1.png" alt="" class="" @click="goback()">
        <img src="@/assets/images/back-foot-2.png" alt="" class="back-foot-2">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isWeChat: false,
      isIOS: false
    };
  },
  created() {
    this.checkWeChat();
  },
  methods: {
    goback(){
        this.$router.go(-1)
    },
    // 检查是否是微信浏览器
    checkWeChat() {
      const userAgent = navigator.userAgent.toLowerCase();
      this.isWeChat = userAgent.indexOf("micromessenger") !== -1;
      this.isIOS = /iphone|ipad|ipod/.test(userAgent);
    },
  },
};
</script>

<style lang="scss" scoped>
.back-foot {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  width: 100vw;
  background-color:#f8f8fb ;
  img{
    width: 24px;
    height: 24px;
  }
  .back-foot-2{
    margin-left: 72px;
  }
}
</style>
