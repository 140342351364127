// 获取code方法 在js中不建议使用route.query.code方式
let getUrlCodeParam = (name) => {
    return (
      decodeURIComponent(
        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
          location.href
        ) || [, ""])[1].replace(/\+/g, "%20")
      ) || null
    );
  };
  
  export let getOpenId = async () => {
    return new Promise(async (resolve, reject) => {
      console.log("初始化");
      let openid = "";
      let ua = navigator.userAgent.toLowerCase();
      // 判断是否在微信内部调用 ：/micromessenger/.test(ua)
      if (/micromessenger/.test(ua)) {
        // 判断本地是否获取过openid:(localStorage.getItem("wx-openid"))
        if (!localStorage.getItem("wx-openid")) {
          console.log("微信内部环境");
          // 这里是通过getUrlCodeParam('code')方法处理ulr中的 code
          const code = getUrlCodeParam('code');
          // 这里是有code走这里
          if (code) {
            // 我们这里处理的方法是将code传给接口，接口返回openid
            const res = await reqxxxxxxx(code); // 确保 reqxxxxxxx 是一个有效的请求方法
            openid = res.data ? res.data.openid : "";
            // 拿到openid后将openid存到本地
            localStorage.setItem("wx-openid", openid ? openid : "");
          } else {
            // 如果没用code 我们就走这里
            let redirect_url = encodeURIComponent(window.location.href);
            // 这里是定义的appid,可以通过配置获取，也可以写死在这里；
            let app_id = "wx3f2857484249704c";
            // 这里是默认方法
            // 确保 scope 参数正确
            let scope = "snsapi_base"; // 或者 "snsapi_userinfo"
            let url =
              "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
              app_id +
              "&redirect_uri=" +
              redirect_url +
              "&response_type=code&scope=" +
              scope +
              "&state=1#wechat_redirect";
            window.location.href = url;
          }
          // 这块返回了openid
          return resolve(openid ? openid : "");
        } else {
          // 判断接口有openid就直接获取本地的
          openid = localStorage.getItem("wx-openid");
          return resolve(openid ? openid : "");
        }
      } else {
        console.log("不在微信环境内");
        return resolve("");
      }
    });
  };