import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Yjldownload",
    component: () => import("../views/download/Yjldownload.vue"),
    meta: {
      title: "药聚力助手下载",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/Yjldownload",
    name: "Yjldownload",
    component: () => import("../views/download/Yjldownload.vue"),
    meta: {
      title: "药聚力助手下载",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/YjlUserdownload",
    name: "YjlUserdownload",
    component: () => import("../views/download/YjlUserdownload.vue"),
    meta: {
      title: "药聚力下载",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/agreement/agreement.vue"),
    meta: {
      title: "协议中心",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/giveservice",
    name: "giveservice",
    component: () => import("../views/agreement/giveservice.vue"),
    meta: {
      title: "服务协议",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/agreement/privacy.vue"),
    meta: {
      title: "隐私协议",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/UserService",
    name: "UserService",
    component: () => import("../views/agreement/UserService.vue"),
    meta: {
      title: "药聚力服务协议",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/UserPrivacy",
    name: "UserPrivacy",
    component: () => import("../views/agreement/UserPrivacy.vue"),
    meta: {
      title: "药聚力隐私政策",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/UserPurchase",
    name: "UserPurchase",
    component: () => import("../views/agreement/UserPurchase.vue"),
    meta: {
      title: "药聚力网上采购协议",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/serve",
    name: "serve",
    component: () => import("../views/serve/serve.vue"),
    meta: {
      title: "帮助中心",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/open",
    name: "open",
    component: () => import("../views/serve/open.vue"),
    meta: {
      title: "药聚力白条开通指南",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/introduce",
    name: "introduce",
    component: () => import("../views/serve/introduce.vue"),
    meta: {
      title: "购药安全",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/passed",
    name: "passed",
    component: () => import("../views/baoli/passed.vue"),
    meta: {
      title: "加载中...",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/salesmanShare",
    name: "salesmanShare",
    component: () => import("../views/controlSales/salesmanShare.vue"),
    meta: {
      title: "分享清单",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/orderList",
    name: "orderList",
    component: () => import("../views/orderList/index.vue"),
    meta: {
      title: "订单列表",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/Doodetails",
    name: "Doodetails",
    component: () => import("../views/orderList/Doodetails.vue"),
    meta: {
      title: "商品详情",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/orderGoods",
    name: "orderGoods",
    component: () => import("../views/orderList/orderGoods.vue"),
    meta: {
      title: "订单商品列表",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/certification",
    name: "certification",
    component: () => import("../views/orderList/certification.vue"),
    meta: {
      title: "质检报告",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/logistics",
    name: "logistics",
    component: () => import("../views/orderList/logistics.vue"),
    meta: {
      title: "物流信息",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/waybill",
    name: "waybill",
    component: () => import("../views/orderList/waybill.vue"),
    meta: {
      title: "随货同行单",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/waybillOperation",
    name: "waybillOperation",
    component: () => import("../views/orderList/waybillOperation.vue"),
    meta: {
      title: "随货同行单详情",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
    meta: {
      title: "登录",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/phoneLogin",
    name: "phoneLogin",
    component: () => import("../views/login/phoneLogin.vue"),
    meta: {
      title: "验证码",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/store",
    name: "store",
    component: () => import("../views/login/store.vue"),
    meta: {
      title: "请选择门店",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/subOrderSucc",
    name: "subOrderSucc",
    component: () => import("../views/controlSales/subOrderSucc.vue"),
    meta: {
      title: "提交成功",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/duigongzhuanzhang",
    name: "duigongzhuanzhang",
    component: () => import("../views/payMethods/duigongzhuanzhang.vue"),
    meta: {
      title: "对公转账",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/xianxiazhifu",
    name: "xianxiazhifu",
    component: () => import("../views/payMethods/xianxiazhifu.vue"),
    meta: {
      title: "线下支付",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/saomazhifu",
    name: "saomazhifu",
    component: () => import("../views/payMethods/saomazhifu.vue"),
    meta: {
      title: "扫码支付",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/paySucc",
    name: "paySucc",
    component: () => import("../views/payMethods/paySucc.vue"),
    meta: {
      title: "支付成功",
      icon: "dashboard",
      affix: true,
    },
  },
  {
    path: "/zfb",
    name: "zfb",
    component: () => import("../views/payMethods/zfb.vue"),
    meta: {
      title: "支付宝",
      icon: "dashboard",
      affix: true,
    },
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  next();
});
export default router;
