<template>
  <div v-if="visible" class="toast">
    {{ message }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      message: "",
      timeout: 3000, // 默认显示3秒
    };
  },
  methods: {
    show(message, timeout = 3000) {
      this.message = message;
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, timeout);
    },
  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  /* bottom: 20px; */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  line-height: 22px;
  font-size: 14px;
  border-radius: 10px;
  z-index: 9999999999;
  /* animation: fadein 0.5s, fadeout 0.5s 2.5s; */
}

/* @keyframes fadein {
    from { bottom: 0; opacity: 0; }
    to { bottom: 20px; opacity: 1; }
  }
  
  @keyframes fadeout {
    from { bottom: 20px; opacity: 1; }
    to { bottom: 0; opacity: 0; }
  } */
</style>
