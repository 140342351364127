import request from "@/api/JavaRequest";
// 用户
let basePtuser = "/ptuser";
// 购物车
let basePtcart = "/ptcart";
// 商品
let basePtgoods = "/ptgoods";
// 公共
let basePtsales = "/ptsales";
// 金融
let basePtfinance = "/ptfinance";
//
let baseMgengine = "/mgengine";
//
let baseYjlrule = "/yjlrule";
let baseYjluser = "/yjluser";
let baseYjlgoods = "/yjlgoods";

// 基本信息
export function getTop(params) {
  return request({
    url: "/takeStockStatement/getTop",
    method: "get",
    params,
  });
}
// 盈亏报表明细
export function getProfitLossPage(data) {
  return request({
    url: "/takeStockStatement/getProfitLossPage",
    method: "post",
    data,
  });
}
//查询药聚力助手协议
export function getSalesmanAgreement(params) {
  return request({
    url: basePtsales + "/sso/getSalesmanAgreement",
    method: "get",
    params,
  });
}
//查询版本号
export function h5appVersion(params) {
  return request({
    url: basePtuser + "/UserLogin/h5appVersion",
    method: "get",
    params,
  });
}

//查询支付方式
export function obtainPaymentList(data) {
  data.channel = "4";
  return request({
    url: baseYjlrule + "/pay/obtainPaymentList",
    method: "post",
    data,
  });
}
//支付
export function orderPayment(data) {
  data.channel = "4";
  return request({
    url: baseYjlrule + "/pay/orderPayment",
    method: "post",
    data,
  });
}
//切换门店
export function changeUser(params) {
  return request({
    url: baseYjluser + "/user/UserLogin/changeUser",
    method: "get",
    params,
  });
}

/** 分享清单 *******************************************/
// 查询分享商品清单
export function searchShareGoodsList(data) {
  return request({
    url: baseYjluser + "/shoppingCart/searchShareGoodsList",
    method: "post",
    data,
  });
}
//查询配送方式
export function deliveryMethod(params) {
  return request({
    url: baseYjluser + `/userOrder/deliveryMethod/${params}`,
    method: "get",
  });
}
// 提交订单(分享清单)
export function submitShareOrder(data) {
  return request({
    url: baseYjluser + "/userOrder/submitShareOrder",
    method: "post",
    data,
  });
}
// 提交订单(分享清单 -未登录)
export function noLoginRequiredSubmitShareOrder(data) {
  return request({
    url: baseYjluser + "/userOrder/noLoginRequiredSubmitShareOrder",
    method: "post",
    data,
  });
}
// 查看用户支付方式类型列表
export function findUserPaymentList(data) {
  return request({
    url: baseYjluser + "/userOrder/findUserPaymentList",
    method: "post",
    data,
  });
}
// 查看用户支付方式类型列表(分享清单 -未登录)
export function noTokenFindUserPaymentList(data) {
  return request({
    url: baseYjluser + "/userOrder/noTokenFindUserPaymentList",
    method: "post",
    data,
  });
}
// 批量查询商品
export function carGoodsList(data) {
  return request({
    url: basePtgoods + "/user/goods/carGoodsList",
    method: "post",
    data,
  });
}
// 未登录批量查询商品
export function carGoodsListByNotLogin(data) {
  return request({
    url: basePtgoods + "/user/goods/carGoodsListByNotLogin",
    method: "post",
    data,
  });
}
//提交订单获取地址
export function getReceivingAddress(params) {
  return request({
    url: basePtuser + "/UserInfo/getReceivingAddress",
    method: "get",
    params,
  });
}
//提交订单获取地址
export function UserInfoget(params) {
  return request({
    url: basePtuser + "/UserInfo/get",
    method: "get",
    params,
  });
}
//查询默认发票选项
export function findUserBillIDefault(data) {
  return request({
    url: baseYjluser + "/userOrder/findUserBillIDefault",
    method: "post",
    data,
  });
}
//获取清单中的数据
export function getInventory(params) {
  return request({
    url: basePtsales + "/personal/getInventory",
    method: "get",
    params,
  });
}
//加入购物车
export function addGoodsCart(data) {
  return request({
    url: baseYjluser + "/shoppingCart/addGoodsCart",
    method: "post",
    data,
  });
}
//查看购物车数量
export function getUserCartCount(params) {
  return request({
    url:
      baseYjluser +
      `/shoppingCart/getUserCartCount/${params.data}/${params.val}`,
    method: "get",
    params,
  });
}
//商品详情
export function getGoodsDetail(params) {
  return request({
    url: basePtgoods + "/user/goods/getGoodsDetail",
    method: "get",
    params,
  });
}
//加入购物车
export function findSaleProtectionBySupplierId(data) {
  return request({
    url: basePtgoods + "/user/goods/findSaleProtectionBySupplierId ",
    method: "post",
    data,
  });
}
//获取分享图片
export function shareDetail(params) {
  return request({
    url: basePtgoods + "/goods/share/detail/user",
    method: "get",
    params,
  });
}
//商品详情新
export function getGoodsDetailNew(params) {
  return request({
    url: baseYjlgoods + "/user/goods/obtainGoodsDetail",
    method: "get",
    params,
  });
}
//业务员店铺获取业务员手机号
export function selectSalesmanMobile(params) {
  return request({
    url: baseYjluser + "/shareList/selectSalesmanMobile",
    method: "get",
    params,
  });
}
//绑定分享码
export function bindSalesPerson(params) {
  return request({
    url: basePtuser + `/PersonalCenter/bindSalesPerson/${params}`,
    method: "get",
  });
}
//获取分享二维码中的信息
export function getQRCodeInfo(params) {
  return request({
    url: basePtsales + "/personal/getQRCodeInfo",
    method: "get",
    params,
  });
}
//获取订单列表
export function checkLoginList(params) {
  return request({
    url: basePtuser + "/order/checkLoginList",
    method: "get",
    params,
  });
}
//绑定订单详情
export function orderinfo(params) {
  return request({
    url: basePtuser + `/orderItem/info/${params}`,
    method: "get",
  });
}
//确认收货
export function confirmReceiving(params) {
  return request({
    url: basePtuser + `/orderItem/confirmReceiving/${params}`,
    method: "get",
  });
}
//上传图片
export function upload() {
  return basePtuser + "/common/upload";
}
// 药聚力-订单详情（根据订单id查询回执单)
export function selectSingleReceipt(params) {
  return request({
    url: basePtuser + "/orderItem/selectSingleReceipt",
    method: "get",
    params,
  });
}
// 药聚力-订单详情（含麻药品上传回执单)
export function uploadingOrder(params) {
  return request({
    url: basePtuser + "/orderItem/uploading",
    method: "get",
    params,
  });
}
// 绑定订单详情 未登录
export function orderNewInfo(params) {
  return request({
    url: basePtuser + `/orderItem/newInfo/${params}`,
    method: "get",
  });
}
// 获取出库单号
export function getInvoiceWithTheGoods(params) {
  return request({
    url: basePtuser + "/orderItem/getInvoiceWithTheGoods",
    method: "get",
    params,
  });
}
// 获取发票列表
export function getInvList(params) {
  return request({
    url: baseYjluser + `/user/order/getInvList/${params}`,
    method: "get",
  });
}
// 订单列表-再次购买
export function bugAgain(data) {
  return request({
    url: baseYjluser + "/user/order/bugAgain",
    method: "post",
    data,
  });
}
// 取消订单
export function cancelOrder(data) {
  return request({
    url: baseYjluser + "/after-sales-order/cancelOrder",
    method: "post",
    data,
  });
}
// 查询所有支付方式
export function selectPaymentMethod(params) {
  return request({
    url: baseYjluser + "/userOrder/selectPaymentMethod",
    method: "get",
    params,
  });
}
// 根据药品id获取资质图片信息
export function getQualificationInfo(params) {
  return request({
    url: basePtgoods + `/activity/getQualificationInfo/${params}`,
    method: "get",
  });
}
// 物流查询
export function userQueryLogistic(params) {
  return request({
    url: basePtuser + `/orderItem/userQueryLogistic/${params}`,
    method: "get",
  });
}
// 订单计时查询
export function getOrderIdsAndTime(params) {
  return request({
    url: basePtuser + "/order/getOrderIdsAndTime",
    method: "get",
    params,
  });
}
// 订单查询
export function corporateConfigGet(params) {
  return request({
    url: baseMgengine + "/supplier/corporate/config/get",
    method: "get",
    params,
  });
}

// 登录
// 药聚力账号登录
export function cohesionAccount(data) {
  return request({
    url: basePtuser + "/UserLogin/cohesionAccount/login",
    method: "post",
    data,
  });
}
// 药易购账号登录
export function accountLogin(data) {
  return request({
    url: baseYjluser + "/user/UserLogin/account/login",
    method: "post",
    data,
  });
}
// 获取验证码
export function sendCode(params) {
  return request({
    url: baseYjluser + "/user/Sms/sendCode",
    method: "get",
    params,
  });
}
// 手机号登录
export function mobileLogin(data) {
  return request({
    url: baseYjluser + "/user/UserLogin/mobile/login",
    method: "post",
    data,
  });
}

// 选择门店
export function choseStore(params) {
  return request({
    url: baseYjluser + "/user/UserLogin/login",
    method: "get",
    params,
  });
}

// 微信授权
export function obtainWechatPublicAccountInfo(params) {
  return request({
    url: baseYjlrule + "/requestVerification/obtainWechatPublicAccountInfo",
    method: "get",
    params,
  });
}
