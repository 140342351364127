<template>
  <div class="merchandise-forbid">
    <div class="goods-details">
      <div class="goods-img">
        <div
          class="parcel"
          v-if="v.activityType"
          :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
        >
          <img
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
            :src="getImgSrc('giftbox3.png')"
            mode=""
            v-if="v.activityType == 4 && v.goodsSource == 1"
            @click="godetais(v)"
          />
          <img
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
            :src="getImgSrc('tradeIn3.png')"
            mode=""
            v-if="v.activityType == 17 && v.goodsSource == 1"
            @click="godetais(v)"
          />
        </div>

        <img
          :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
          :src="getImgSrc('notgoodspic-small.png')"
          mode=""
          v-if="
            v.picture == '-' ||
            v.pictureUrl == '-' ||
            v.picture == '' ||
            v.pictureUrl == ''
          "
          @click="godetais(v)"
        />
        <div class="" v-else>
          <img
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
            :src="v.picture || v.pictureUrl"
            mode=""
            v-if="!v.imgErr"
            @error="imgputErr(v)"
            @click="godetais(v)"
          />
          <img
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
            :src="getImgSrc('notgoodspic-small.png')"
            mode=""
            v-else
            @click="godetais(v)"
          />
        </div>
      </div>
      <div class="business-message">
        <div class="goods-name">
          {{ v.name }}
        </div>
        <div class="goods-tag">
          <span v-if="v.deliveryService == 1" class="autotrophy">自营仓</span>
          <span
            class="share"
            v-if="
              v.whetherShared == 0 &&
              (v.activityType == 4 || v.activityType == 17) &&
              v.goodsSource == 2 &&
              v.source == 2
            "
            >赠品</span
          >
          <span
            class="share"
            v-if="
              v.whetherShared == 0 &&
              (v.activityType == 4 || v.activityType == 17) &&
              v.goodsSource == 2 &&
              v.source == 1
            "
            >换购商品</span
          >

          <span class="country-tag" v-if="v.nationalDrugCode">国家码</span>
          <span class="gather-tag" v-if="v.collection">集</span>
        </div>
        <div v-if="v.specification" class="infos">
          <img :src="ImgSrc('icon/gui.png')" mode="" />
          <span>{{ v.specification }}</span
          ><span v-if="v.unit">({{ v.unit }})</span>
        </div>
        <div class="infos">
          <img :src="ImgSrc('icon/chang.png')" mode="" />
          <span class="oneline">{{ v.manufacturer }}</span>
        </div>

        <div
          style="color: #091229; font-size: 13px"
          v-if="v.expirationDate != null"
        >
          <img
            :src="ImgSrc('icon/near-effective.png')"
            mode=""
            v-if="v.nearTerm"
          />
          <img :src="ImgSrc('icon/xiao.png')" mode="" v-else />
          <span v-if="v.expirationDate != null">{{ v.expirationDate }}</span>
          <!-- <span	v-if="v.expirationDate==null">无</span> -->
        </div>
        <div class="goods-money">
          <div class="">
            <div class="goods-price" v-if="v.averagePrice">
              {{ v.averagePrice }}
            </div>
            <div class="goods-price" v-if="!v.averagePrice && v.sellingPrice">
              {{ disposeTofixed(v.sellingPrice)[0]
              }}{{ disposeTofixed(v.sellingPrice)[1] ? "." : "" }}
              <span>{{ disposeTofixed(v.sellingPrice)[1] }}</span>
            </div>

            <div class="refund" v-if="v.refundAmount > 0">
              <span class="" style="line-height: 1"> 退款 </span>
              <div
                class=""
                style="display: flex; padding-top: 3.5px; line-height: 1"
              >
                <span style="font-size: 13px; color: #091229">￥</span>
                {{ disposeTofixed(v.refundAmount)[0]
                }}{{ disposeTofixed(v.refundAmount)[1] ? "." : "" }}
                <span>{{ disposeTofixed(v.refundAmount)[1] }}</span>
              </div>
            </div>
          </div>

          <div>x{{ v.goodsQuantity || v.quantity }}</div>
        </div>
      </div>
    </div>

    <div class="gift" v-if="v.activityVo && v.activityVo.activityType == 4">
      <div class="icon">
        <img src="@/assets/wx_image/gift.png" mode="" /><span>赠品</span>
      </div>
      <div
        class="gift-flex"
        v-for="(item, index) in v.activityGoodsList"
        :key="index"
      >
        <div class="gift-list" @click="giftDetails">
          {{ item.goodsName }}
          <div>
            x{{ item.quantity }}
            <img src="@/assets/wx_image/right2.png" mode="" />
          </div>
        </div>
      </div>
    </div>
    <div class="" v-if="v.activityVo && v.activityVo.activityType == 17">
      <div
        class="goods-details mt10"
        v-for="(item, index) in v.activityGoodsList"
        :key="index"
      >
        <div class="goods-img">
          <img
            :src="item.picture || item.pictureUrl || item.activityGoodsPic"
            mode=""
            v-if="
              item.picture != '-' &&
              item.pictureUrl != '-' &&
              item.activityGoodsPic != '-' &&
              item.activityGoodsPic != ''
            "
          />
          <img
            :src="getImgSrc('notgoodspic.png')"
            mode=""
            v-if="
              item.picture == '-' ||
              item.pictureUrl == '-' ||
              item.activityGoodsPic == '-' ||
              item.activityGoodsPic == ''
            "
          />
        </div>
        <div class="business-message">
          <div class="goods-name">
            <span class="share">换购商品</span>
            <span class="goods-name-name">{{ item.goodsName }}</span>
          </div>
          <div class="infos">
            <img :src="ImgSrc('icon/chang.png')" mode="" />
            <span class="oneline">{{ item.manufacturer }}</span>
          </div>
          <div v-if="item.specification" class="infos">
            <img :src="ImgSrc('icon/gui.png')" mode="" />
            <span>{{ item.specification }}</span
            ><span v-if="item.unit">({{ item.unit }})</span>
          </div>
          <div class="goods-money">
            <div class="goods-price">
              {{ v.averagePrice || v.sellingPrice }}
            </div>
            <div>x{{ item.goodsQuantity || item.quantity }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    v: {
      type: Object,
      default: () => {
        imgErr: false;
      },
    },
    bussinessId: {
      type: Number,
      default: 0,
    },
    imgWidth: {
      type: String,
      default: "120",
    },
    imgHeight: {
      type: String,
      default: "120",
    },
  },
  data() {
    return {
      allMoney: 0,
    };
  },
  methods: {
    imgputErr(val) {
      //   this.v.imgErr = true;
    },
    choose(v) {
      this.$emit("pChoose", v);
    },
    goGoods(v) {
      this.$emit("goGoods", v);
    },
    changeNum(event, v) {
      this.$emit("changeNum", event, v);
    },
    openInput(v) {
      this.$emit("openInput", v);
    },
    Listening(v) {
      this.$emit("Listening", v);
    },
    godetais(v) {
      return;
      if (v.whetherShared == 1) {
        uni.navigateTo({
          url: "/pages/goods/goods?id=" + v.goodsId + "&shareFlag=" + true,
        });
      }
      if (v.whetherShared == 0 && v.goodsSource == 1) {
        uni.navigateTo({
          url: "/pages/goods/goods?id=" + v.goodsId + "&shareFlag=" + false,
        });
      }
      if (v.whetherShared == 0 && v.goodsSource == 2) {
        uni.navigateTo({
          url:
            "/pages/goods/activity/activity?id=" +
            v.goodsId +
            "&number=" +
            v.quantity +
            "&price=" +
            v.sellingPrice,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.merchandise-forbid {
  width: 100%;
}

.goods-details {
  display: flex;

  .goods-img {
    box-sizing: border-box;
    margin-right: 10px;
    position: relative;

    .parcel {
      position: absolute;

      .explain {
        position: absolute;
        bottom: 9px;
        right: 2px;
        z-index: 2;
        font-size: 10px;
        color: #fff;
        width: 70px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .explain1 {
        bottom: 5px;
      }
    }
  }

  .business-message {
    font-size: 14px;
    color: #666;
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .goods-name {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 190px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 15px !important;
      font-weight: bold;
    }

    .goods-tag {
      margin: 4px 0;

      .share {
        background-color: #ff6633;
      }

      .country-tag,
      .gather-tag {
        color: #eb2c35;
        border: 1px solid #eb2c35;
        margin-left: 4px;
      }

      .autotrophy {
        color: #ff6633 !important;
        border: 1px solid #ff6633;
      }

      span {
        color: #fff;
        font-size: 11px;
        // height: 16px;
        // line-height: 16px;
        text-align: center;
        padding: 0 4px;
        font-weight: bold;
        border-radius: 4px;
      }
    }

    .infos {
      color: #091229;
      font-size: 13px;
      margin-bottom: 2px;
    }

    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }

    div {
      display: flex;
      align-items: center;
      color: #647083;
      font-size: 12px;

      .oneline {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
      }
    }

    .goods-money {
      display: flex;
      justify-content: space-between;
      color: #ff484e;
      margin-top: 4px;

      .goods-price {
        font-family: D-DIN-PRO-Bold;
        font-size: 20px;
        color: #ff484e;
        display: inline-block;

        span {
          font-size: 14px !important;
          margin-left: 1px;
        }
      }

      .goods-price::before {
        content: "￥";
        font-size: 14px;
        font-weight: bold;
      }

      div {
        color: #929bab;
        font-size: 14px;
      }

      .refund {
        margin-left: 6px;
        color: #091229;
        font-size: 12px;
        line-height: 22px;
        display: flex;
        align-items: center;

        div {
          font-size: 13px;
          font-family: D-DIN-PRO-Regular;
          color: #091229;
        }

        // div:before {
        // 	content: '￥';
        // 	font-size: 13px;
        // 	color: #091229;
        // }
      }
    }
  }
}

.gift {
  padding: 2px;
  font-size: 14px;

  .icon {
    margin-right: 10px;
    display: flex;
    align-items: center;

    img {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }

    span {
      color: #e63e3f;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .gift-flex {
    flex: 1;
  }

  .gift-list {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    div {
      display: flex;
      align-items: center;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.mt10 {
  margin-top: 10px;
}
</style>
