import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Element from "element-ui";
import "./permission"; // permission control
import "element-ui/lib/theme-chalk/index.css";
import * as echarts from "echarts";
import axios from "axios"; //引入axios
import "@/assets/css/reset.css";
import "@/assets/css/index.scss";
import mixin from "./mixins";
import VueClipboard from "vue-clipboard2";
import yjlLoading from "@/components/yjl-loading/yjl-loading.vue";
import yjlAffirm from "@/components/yjl-affirm/yjl-affirm.vue";
import pay from "@/components/pay/index.vue";
// 引入组件商品信息-普通商品-不可操作-订单
import merchandiseorder from "@/components/merchandise/order/order.vue";
// 引入组件商品信息-普通商品-不可操作
import merchandiseforbid from "@/components/merchandise/forbid/forbid.vue";
// 引入组件安卓返回上一页
import backFoot from "@/components/backFoot/backFoot.vue";
import Toast from "@/components/toast.vue";
import UpimgList from "@/components/UpimgList/UpimgList.vue";

// 创建一个 Vue 实例来管理 Toast
const toast = new Vue({
  render: (h) => h(Toast),
}).$mount();
// 将 toast 实例挂载到 body 上
document.body.appendChild(toast.$el);

// 将 toast 方法挂载到 Vue 原型上
Vue.prototype.$toast = toast.$children[0];
// 导出 ToastInstance
export { toast };


Vue.prototype.$echarts = echarts; //挂载到Vue实例上面

// 全局组件挂载
Vue.component("yjl-loading", yjlLoading);
Vue.component("yjl-affirm", yjlAffirm);
Vue.component("pay", pay);
Vue.component("merchandise-order", merchandiseorder);
Vue.component("merchandise-forbid", merchandiseforbid);
Vue.component("back-foot", backFoot);
Vue.component("UpimgList", UpimgList);
Vue.mixin(mixin);
Vue.use(Element);
Vue.use(VueClipboard);

Vue.prototype.getImgSrc = function (img) {
  return "https://hzyyb.oss-cn-shenzhen.aliyuncs.com/images/" + img;
};
// 图片前缀
Vue.prototype.ImgSrc = function (img) {
  return "https://yygpro-oss.oss-cn-shenzhen.aliyuncs.com/web/" + img;
};
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

new Vue({
  methods: {
    // base64 转为 file
    base64ToFile(urlData, fileName) {
      let arr = urlData.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], fileName, { type: mime });
    },
  },
  router,
  render: (h) => h(App),
}).$mount("#app");
