<template>
  <div class="merchandise-forbid">
    <div class="goods-details">
      <div
        class="goods-img"
        :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
      >
        <div class="parcel" v-if="v.activityVo">
          <img
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
            :src="getImgSrc('giftbox3.png')"
            mode=""
            v-if="v.activityVo.activityType == 4"
          />
          <img
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
            :src="getImgSrc('tradeIn3.png')"
            mode=""
            v-if="v.activityVo.activityType == 17"
          />
        </div>
        <img
          :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
          :src="getImgSrc('notgoodspic-small.png')"
          mode=""
          v-if="
            v.picture == '-' ||
            v.pictureUrl == '-' ||
            v.picture == '' ||
            v.pictureUrl == ''
          "
        />
        <img
          :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
          :src="getImgSrc('notgoodspic-small.png')"
          mode=""
          v-else-if="[v.picture, v.pictureUrl].includes('')"
        />

        <div class="" v-else>
          <img
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
            :src="v.picture || v.pictureUrl"
            mode=""
            v-if="!imgErr"
            @error="imgputErr(v)"
          />
          <img
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
            :src="getImgSrc('notgoodspic-small.png')"
            mode=""
            v-else
          />
        </div>
      </div>

      <div class="business-message">
        <div class="goods-name">
          {{ v.name }}
        </div>
        <div class="goods-tag">
          <span v-if="v.deliveryService == 1" class="autotrophy">自营仓</span>
          <span class="country-tag" v-if="v.nationalDrugCode">国家码</span>
          <span class="gather-tag" v-if="v.collection">集</span>
        </div>
        <div v-if="v.specification" class="goods-info">
          <img :src="ImgSrc('icon/gui_1.png')" mode="" />
          <span>{{ v.specification }}</span>
          <span v-if="v.unit">({{ v.unit }})</span>
        </div>
        <div class="goods-info">
          <img :src="ImgSrc('icon/chang_1.png')" mode="" />
          <span class="oneline">{{ v.manufacturer }}</span>
        </div>

        <div class="goods-info">
          <img :src="ImgSrc('icon/xiao_1.png')" mode="" />
          <span v-if="v.expirationDate != null">{{ v.expirationDate }}</span>
          <span v-if="v.expirationDate == null">无</span>
        </div>
        <div class="goods-money">
          <div class="moneys">
            <img
              v-if="v.preferentialType != 0 && v.goodsList.length == 1"
              :src="ImgSrc('icon/special-offer.png')"
              mode=""
              class="special-offer"
            />
            ￥
            <span class="average-price" v-if="v.averagePrice">
              {{ $p.disposeTofixed(v.averagePrice)[0]
              }}{{ $p.disposeTofixed(v.averagePrice)[1] ? "." : "" }}
            </span>
            <span
              style="font-size: 14px; margin-left: 1px; margin-top: 5px"
              class="average-price"
              v-if="v.averagePrice"
              >{{ $p.disposeTofixed(v.averagePrice)[1] }}</span
            >

            <span
              class="average-price"
              v-if="!v.averagePrice && v.sellingPrice"
            >
              {{ $p.disposeTofixed(v.sellingPrice)[0]
              }}{{ $p.disposeTofixed(v.sellingPrice)[1] ? "." : "" }}
            </span>
            <span
              style="font-size: 14px; margin-left: 1px; margin-top: 5px"
              class="average-price"
              v-if="!v.averagePrice && v.sellingPrice"
              >{{ $p.disposeTofixed(v.sellingPrice)[1] }}</span
            >
          </div>
          <div style="color: #091229">x{{ v.goodsQuantity || v.quantity }}</div>
        </div>
      </div>
    </div>
    <div class="goods-price" v-if="v.goodsList.length > 1">
      <div
        class="multi-price"
        v-for="(item, index) in v.goodsList"
        :key="index"
      >
        <div class="none"></div>
        <div class="price-list">
          <div class="">
            <span v-if="index == 0">特价</span>
            <span v-if="index == 1">原价</span>
            <span style="font-size: 13px; font-family: D-DIN-PRO-Regular"
              >￥{{ item.sellingPrice }}</span
            >
          </div>
          <div class="" style="font-size: 12px; color: #091229">
            x{{ item.purchaseQuantity }}
          </div>
        </div>
      </div>
    </div>
    <div
      class=""
      v-if="
        v.activityVo &&
        (v.activityVo.activityType == 17 || v.activityVo.activityType == 4)
      "
    >
      <div
        class="goods-details mt10"
        v-for="(item, index) in v.activityGoodsList"
        :key="index"
      >
        <div
          class="goods-img"
          :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
        >
          <img
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
            :src="item.picture || item.pictureUrl || item.activityGoodsPic"
            mode=""
            v-if="
              item.picture != '-' &&
              item.pictureUrl != '-' &&
              item.activityGoodsPic != '-' &&
              item.activityGoodsPic != ''
            "
          />
          <img
            :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
            :src="getImgSrc('notgoodspic.png')"
            mode=""
            v-if="
              item.picture == '-' ||
              item.pictureUrl == '-' ||
              item.activityGoodsPic == '-' ||
              item.activityGoodsPic == ''
            "
          />
        </div>
        <div class="business-message">
          <div class="goods-name">
            <img
              :src="ImgSrc('conversion-lable.png')"
              mode=""
              v-if="v.activityVo.activityType == 17"
              class="lable-img"
            />
            <img
              :src="ImgSrc('gift-lable.png')"
              mode=""
              v-if="v.activityVo.activityType == 4"
              class="lable-img"
            />
            <div class="goodsName">
              {{ item.goodsName }}
            </div>
          </div>

          <div v-if="item.specifications" class="goods-info">
            <img
              style="width: 28rpx; height: 28rpx; margin-right: 8rpx"
              :src="ImgSrc('icon/gui.png')"
              mode=""
            />
            <span>{{ item.specifications }}</span>
          </div>
          <div class="goods-info">
            <img
              style="width: 28rpx; height: 28rpx; margin-right: 8rpx"
              :src="ImgSrc('icon/chang.png')"
              mode=""
            />
            <span class="oneline">{{ item.manufacturer }}</span>
          </div>

          <div class="goods-info">
            <img
              style="width: 28rpx; height: 28rpx; margin-right: 8rpx"
              :src="ImgSrc('icon/near-effective.png')"
              mode=""
              v-if="item.nearTerm"
            />
            <img
              style="width: 28rpx; height: 28rpx; margin-right: 8rpx"
              :src="ImgSrc('icon/xiao.png')"
              mode=""
              v-else
            />
            <span v-if="item.expirationTime != null">{{
              item.expirationTime
            }}</span
            ><span v-if="item.expirationTime == null">无</span>
          </div>
          <div class="goods-money">
            <div class="moneys">
              ￥
              <span class="average-price" v-if="item.averagePrice">
                {{ $p.disposeTofixed(item.averagePrice)[0]
                }}{{ $p.disposeTofixed(item.averagePrice)[1] ? "." : "" }}
              </span>
              <span
                style="font-size: 14px; margin-left: 1px; margin-top: 5px"
                class="average-price"
                v-if="item.averagePrice"
                >{{ $p.disposeTofixed(item.averagePrice)[1] }}</span
              >
              <span
                class="average-price"
                v-if="!item.averagePrice && item.sellingPrice"
              >
                {{ $p.disposeTofixed(item.sellingPrice)[0]
                }}{{ $p.disposeTofixed(item.sellingPrice)[1] ? "." : "" }}
              </span>
              <span
                style="font-size: 14px; margin-left: 1px; margin-top: 5px"
                class="average-price"
                v-if="!item.averagePrice && item.sellingPrice"
                >{{ $p.disposeTofixed(item.sellingPrice)[1] }}</span
              >
            </div>
            <div>x{{ item.goodsQuantity || item.quantity }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    v: {
      type: Object,
      default: () => {
        imgErr: false;
      },
    },
    bussinessId: {
      type: Number,
      default: 0,
    },
    imgWidth: {
      type: String,
      default: "120",
    },
    imgHeight: {
      type: String,
      default: "120",
    },
  },
  data() {
    return {
      allMoney: 0,
      imgErr: false,
    };
  },
  created() {
    console.log(22222, this.v);
  },
  methods: {
    imgputErr(val) {
      // this.imgErr = true;
    },
    choose(v) {
      this.$emit("pChoose", v);
    },
    goGoods(v) {
      this.$emit("goGoods", v);
    },
    changeNum(event, v) {
      this.$emit("changeNum", event, v);
    },
    openInput(v) {
      this.$emit("openInput", v);
    },
    Listening(v) {
      this.$emit("Listening", v);
    },
  },
};
</script>

<style lang="scss" scoped>
.merchandise-forbid {
  width: 100%;
}

.goods-details {
  display: flex;

  .goods-img {
    box-sizing: border-box;
    margin-right: 8px;
    position: relative;

    .parcel {
      position: absolute;

      .explain {
        position: absolute;
        bottom: 9px;
        right: 2px;
        z-index: 2;
        font-size: 10px;
        color: #fff;
        width: 70px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .explain1 {
        bottom: 5px;
      }
    }

    // img {
    // 	width: 120px;
    // 	height: 120px;

    // }
  }

  .business-message {
    font-size: 14px;
    color: #647083;
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .goods-info {
      font-size: 13px !important;
      color: #091229 !important;
      // .i-box{
      // 	display: inline-block;
      // 	color: #647083;
      // 	font-size: 10px;
      // 	width: 14px;
      // 	height: 14px;
      // 	line-height: 13px;
      // 	text-align: center;
      // 	border: 1px solid #647083;
      // 	box-sizing: border-box;
      // 	border-radius: 2px;
      // 	margin-right: 4px;
      // }
      display: flex;
      align-items: center;

      img {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
    }

    .goods-name {
      width: 230px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px !important;
      font-weight: bold;
      display: flex;
      align-items: center;

      .lable-img {
        width: 32px;
        height: 18px;
        margin-right: 2px;
      }

      .goodsName {
        min-width: 0;
        flex: 1;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
      }
    }

    .goods-tag {
      // margin: 4px 0;
      span {
        margin: 4px 0;
      }
      .share {
        background-color: #ff6633;
      }

      .change {
        height: 16px;
        background: #ff6633;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        color: #ffffff;
      }

      .country-tag,
      .gather-tag {
        color: #eb2c35;
        border: 1px solid #eb2c35;
        margin-left: 4px;
      }

      .autotrophy {
        color: #ff6633 !important;
        border: 1px solid #ff6633;
        font-weight: bold;
      }

      span {
        color: #fff;
        font-size: 11px !important;
        height: 16px;
        line-height: 16px;
        text-align: center;
        padding: 0 5px;
        font-weight: 400;
        border-radius: 4px;
      }
    }

    div {
      display: flex;
      color: rgba($color: #000000, $alpha: 0.5);
      font-size: 11px;

      .oneline {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
      }
    }

    .goods-money {
      font-size: 14px;
      display: flex;
      justify-content: space-between;

      .moneys {
        color: #ff484e;
        font-weight: bold;
        display: flex;
        align-items: baseline;

        .average-price {
          font-size: 20px;
          font-family: D-DIN-PRO-Bold;
        }

        .bold {
          font-weight: bold;
          font-size: 12px;
        }

        span {
          font-size: 10px;
          font-weight: normal;
        }
      }

      div {
        color: #929bab;
        font-size: 14px;
      }
    }
  }
}
.special-offer {
  width: 23px;
  height: 14px;
  padding: 0;
  top: 1px;
  position: relative;
}
.gift {
  padding: 2px;
  font-size: 14px;

  .icon {
    margin-right: 10px;
    display: flex;
    align-items: center;

    img {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }

    span {
      color: #e63e3f;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .gift-flex {
    flex: 1;
  }

  .gift-list {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    div {
      display: flex;
      align-items: center;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.mt10 {
  margin-top: 10px;
}

.give-name {
  width: 100px;
}

.give-money {
  color: #ff484e;
}
.goods-price {
  background-color: #f8f8fb;
  border-radius: 6px;
  padding: 6px 8px;
  margin-left: 88px;
}
.multi-price {
  display: flex;

  line-height: 20px;
  font-size: 12px;
  color: #666;

  .none {
    // width: 88px;
    // outline: none;
  }

  .price-list {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 12px;
      color: #091229;
    }
  }
}
</style>
