<template>
  <div>
    <div
      class="payList-item"
      v-for="(item, index) in payList"
      :key="index"
      :class="index == 0 ? 'no-bor' : ''"
      @click="gopay(item.payType)"
    >
      <div class="payList-item-left">
        <img :src="item.iconUrl" alt="" class="iconUrl" />
        {{ item.payTypeName }}
        <span v-if="item.remark" class="item-remark">{{ item.remark }}</span>
        <span v-if="item.useCreditLine"
          >¥{{ (item.useCreditLine * 1).toFixed(2) }}</span
        >
      </div>
      <img
        src="@/assets/images/pay/arrow-right.png"
        alt=""
        class="arrow-right"
      />
    </div>
    <yjl-affirm v-if="affirmShow" title="提示" :mess="affirmMess">
      <div class="affirm-btn">
        <div
          style="border-right: 1px solid #eeeeee"
          class=""
          @click="affirmShow = false"
        >
          取消
        </div>
        <div class="confirm" @click="goLogin">去登录</div>
      </div>
    </yjl-affirm>
    <yjl-loading
      background="rgba(000,000,000,0)"
      laodingText="支付请求中..."
      v-if="!isfinish"
    ></yjl-loading>
  </div>
</template>

<script>
import { orderPayment } from "@/api/apiAll/javaUrl";
export default {
  props: {
    payList: Array,
    payOrder: Object,
  },
  data() {
    return {
      affirmShow: false,
      affirmMess: "",
      isfinish: true,
    };
  },
  created() {
    // localStorage.setItem("token", '01d63993-1f32-46ca-a4be-928dae34404c');
    // localStorage.removeItem("token");
    getOpenId().then((res) => {
      console.log(res, "openid");
    });
  },
  methods: {
    goLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    gopay(type) {
      // this.$emit('gopay',payType)
      console.log(type);
      if (type == 0) {
        //对公转账
        this.dgPay();
      } else if (type == 60) {
        //静态二维码支付宝支付
        this.QRPay();
      } else {
        this.isfinish = false;
        this.payApi(type);
      }
    },
    async payApi(type) {
      let callingParty = 4;
      const userAgent = navigator.userAgent.toLowerCase();
      let isWeChat = userAgent.indexOf("micromessenger") !== -1;
      console.log(isWeChat);
      // this.configWeChatJSBridge();
      if (isWeChat) {
        callingParty = 5;
      }
      let params = {
        orderGroupId: this.payOrder.orderGroupId,
        amount: this.payOrder.payment,
        type,
        callingParty,
        isNewVersion: "1",
      };
      let res = await orderPayment(params);
      if (res.code == "000000") {
        if (type == 11) {
          this.saoma(res.content);
        } else if (type == 12) {
          this.zfb(res.content.authcode);
        } else if (type == 1) {
          this.wxPay(res.content);
        } else if (type == 70 || type == 40) {
          this.$router.replace({
            path: "/paySucc",
          });
        }
      } else {
        if (res.code == "600129") {
          this.affirmMess = "请登录后进行支付";
          this.affirmShow = true;
        } else if (res.code == "600130") {
          this.affirmMess =
            "登录用户同分享清单用户不一致，请切换至清单用户登录";
          this.affirmShow = true;
        }
        this.isfinish = true;
      }
    },
    wxPay(data) {
      const userAgent = navigator.userAgent.toLowerCase();
      let isWeChat = userAgent.indexOf("micromessenger") !== -1;
      if (isWeChat) {
      } else {
        this.isfinish = true;
        window.open(data.authcode);
      }
    },
    zfb(data) {
      this.$router.push({
        path: "/zfb",
        query: {
          data,
        },
      });
    },
    saoma(data) {
      let mess = JSON.stringify(data);
      console.log(mess);
      this.$router.push({
        path: "/saomazhifu",
        query: {
          mess,
        },
      });
    },
    dgPay() {
      this.$router.push({
        path: "/duigongzhuanzhang",
        query: {
          businessId: this.payOrder.businessId,
        },
      });
    },
    QRPay() {
      let mess = this.payList.filter((item) => {
        return item.payType == 60;
      });
      mess = mess[0];
      mess = JSON.stringify(mess);
      this.$router.push({
        path: "/xianxiazhifu",
        query: {
          mess,
        },
      });
    },
    // 微信支付
    callWechatPay(payParams) {
      // 引入微信支付 JS-SDK
      const script = document.createElement("script");
      script.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
      script.onload = () => {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: payParams.appId, // 必填，公众号的唯一标识
          timestamp: payParams.timeStamp, // 必填，生成签名的时间戳
          nonceStr: payParams.nonceStr, // 必填，生成签名的随机串
          signature: payParams.paySign, // 必填，签名
          jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
        });

        wx.ready(() => {
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          wx.chooseWXPay({
            timestamp: payParams.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: payParams.nonceStr, // 支付签名随机串，不长于 32 位
            package: payParams.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: payParams.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: payParams.paySign, // 支付签名
            success(res) {
              console.log("支付成功", res);
              // 支付成功后的回调函数
            },
            fail(res) {
              console.log("支付失败", res);
              // 支付失败后的回调函数
            },
          });
        });

        wx.error((res) => {
          console.error("微信JS-SDK配置失败", res);
        });
      };
      document.body.appendChild(script);
    },
  },
};
</script>

<style lang="scss" scoped>
.payList-item {
  height: 56px;
  display: flex;
  align-items: center;
  border-top: 1px solid #f2f3f5;
  justify-content: space-between;
  .payList-item-left {
    display: flex;
    align-items: center;
    .item-remark {
      font-size: 14px;
      color: #929bab;
    }
  }
  .iconUrl {
    width: 24px;
    height: 24px;
    padding-right: 8px;
  }
  .arrow-right {
    width: 14px;
    height: 14px;
  }
}
.no-bor {
  border-top: none;
}
.affirm-btn {
  height: 48px;
  border-top: 1px solid #eeeeee;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  line-height: 48px;

  div {
    text-align: center;
    color: #647083;
    flex: 1;
  }

  .confirm {
    font-weight: 600;
    color: #ff5d2c;
  }
}
</style>
