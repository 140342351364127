let mixin = {
  filters: {},
  methods: {
    // 图片前缀
    getImgSrc(img) {
      return "https://yygpro-oss.oss-cn-shenzhen.aliyuncs.com/images/" + img;
    },
    // 图片前缀
    ImgSrc(img) {
      return "https://yygpro-oss.oss-cn-shenzhen.aliyuncs.com/web/" + img;
    },
    // 处理两位小鼠
    disposeTofixed(data) {
      if (
        data === "" ||
        data === null ||
        data === 0 ||
        data === "0" ||
        data === "0.00" ||
        data === "0.0" ||
        data < 0
      ) {
        return [data, ""];
      } else {
        data = (data * 1).toFixed(2).split(".");
        if (data[1] == "00") {
          data[1] = "";
        } else {
          if (data[1].slice(1, 2) == 0) {
            data[1] = data[1].slice(0, 1);
          }
        }
        return data;
      }
    },
  },
};

export default mixin;
