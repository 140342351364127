export default {
  // 如需要点击遮罩层关闭遮罩，请在父组件中子组件标签添加 @cancel='方法名()'
  props: {
    title: {
      type: String, // 3:下拉加载更多  1:加载中 2: 没有更多
      // default: uni.$u.props.alert.title
    },
    mess: {
      type: String, // 3:下拉加载更多  1:加载中 2: 没有更多
      // default: uni.$u.props.alert.title
    },
    operate: {
      type: String, // 3:下拉加载更多  1:加载中 2: 没有更多
      // default: uni.$u.props.alert.title
    },
  },
};
