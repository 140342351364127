let obj = window.location.href;
let index = obj.lastIndexOf("#");
obj = obj.substring(0, index);
/*******php接口*******************************************************************************************/
// let devUrl = 'https://gys.yaojuli.com/apis';// 正式
// let devUrl = 'http://pregys.yaojuli.com/apis';// 灰度
let devUrl = 'http://192.168.40.39:8001/apis';// 测试
// let devUrl = "http://192.168.40.39:9001/apis"; // 开发
// let devUrl = "http://192.168.35.215:9506"// 陈浩
// let devUrl = "http://192.168.35.161:9501"// 侯建
// let devUrl = "http://183.220.29.156:8001/apis";// 临时地址

var phpbaseURL = "";
if (
  window.location.href.indexOf("192.168") >= 0 ||
  obj.indexOf("192") >= 0 ||
  obj.indexOf("localhost") >= 0
) {
  phpbaseURL = devUrl; //本地
} else if (window.location.href.indexOf("preyygproapi") >= 0) {
  phpbaseURL = "http://prept.yaojuli.com:8006/api"; //灰度
} else if (window.location.href.indexOf("222.209") >= 0) {
  phpbaseURL = "http://192.168.40.39:8002/api"; //测试
} else {
  phpbaseURL = "https://pt.yaojuli.com:8006/api"; //正式
}

/*******java接口*******************************************************************************************/
// let devUrlapp =  'http://yygproapi.yaojuli.com';// 正式
// let devUrlapp =  'https://preyygproapi.yaojuli.com';// 灰度
let devUrlapp = "http://222.209.81.152:8003";// 测试
// let devUrlapp = "http://192.168.40.39:9090"; // 开发
// let devUrlapp = "http://192.168.35.234:10007"// 冯汪洋
// let devUrlapp = "http://192.168.35.55:10007"// 冷彤川
// let devUrlapp = "http://183.220.29.156:8003";// 临时地址

var javabaseURL = "";
if (
  window.location.href.indexOf("192.168") >= 0 ||
  obj.indexOf("192") >= 0 ||
  obj.indexOf("localhost") >= 0
) {
  javabaseURL = devUrlapp; //本地
} else if (window.location.href.indexOf("prekx") >= 0) {
  // javabaseURL = "https://preyygproapi.yaojuli.com"; //灰度
  javabaseURL = "http://222.209.81.152:8003"; //测试临时灰度
} else if (window.location.href.indexOf("222.209") >= 0) {
  javabaseURL = "http://222.209.81.152:8003"; //测试
} else {
  javabaseURL = "https://yygproapi.yaojuli.com"; //正式
}

export { javabaseURL, phpbaseURL };
window.localStorage.setItem("javabaseURL", javabaseURL);
window.localStorage.setItem("phpbaseURL", phpbaseURL);
