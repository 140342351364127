<template>
  <div class="pay">
    <div class="pay-box">
      <div class="pay-head">
        选择支付方式
        <img src="@/assets/images/pay/close.png" alt="" @click="closeCover" />
      </div>
      <div class="pay-money">¥{{ payOrder.payment.toFixed(2) }}</div>
      <div class="pay-list-box">
        <div class="pay-list-title" v-if="disposeList.length > 0">支付方式</div>
        <div class="pay-list">
          <div :class="openMore ? '' : 'max-hei-3'">
            <payListCon :payList="disposeList" :payOrder="payOrder"></payListCon>
          </div>
          <div
            class="look-more"
            @click="openMore = true"
            v-if="!openMore && disposeList.length > 3"
          >
            展开
            <img src="@/assets/images/pay/arrow-right.png" alt="" class="">
          </div>
          <div
            class="look-more"
            @click="openMore = false"
            v-if="openMore && disposeList.length > 3"
          >
            收起
            <img src="@/assets/images/pay/arrow-right.png" alt="" class="arrow-right">
          </div>
        </div>
        <div class="pay-list-title" v-if="tripartiteList.length > 0">
          第三方信贷资金渠道
        </div>
        <div class="pay-list">
          <payListCon :payList="tripartiteList" :payOrder="payOrder"></payListCon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import payListCon from "./list.vue";
import { getOpenId } from "@/utils/getOpenid";
export default {
  components: {
    payListCon,
  },
  props: {
    payOrder: Object,
  },
  data() {
    return {
      disposeList: [], //普通支付方式
      tripartiteList: [], //第三方信贷资金渠道
      openMore: false, //更多
    };
  },
  created() {
    console.log(this.payOrder);
    this.disposeList = this.payOrder.paymentTypeList.filter((item) => {
      return item.payType != 50 && item.payType != 110;
    });
    this.tripartiteList = this.payOrder.paymentTypeList.filter((item) => {
      return item.payType == 50 || item.payType == 110;
    });
    getOpenId().then((res) => {
      console.log(res, "openid");
    });
  },
  methods: {
    // 关闭支付方式
    closeCover() {
      this.$emit("closeCover");
    },
  },
};
</script>

<style lang="scss" scoped>
.pay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.pay-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 15px 10px 34px;
  box-sizing: border-box;
  background-color: #f4f4f7;
  border-radius: 16px 16px 0px 0px;
}
.pay-head {
  text-align: center;
  position: relative;
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  color: #091229;
  img {
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
  }
}
.pay-money {
  padding: 15px 0 5px;
  color: #091229;
  font-size: 30px;
  font-weight: bold;
  line-height: 42px;
  text-align: center;
}
.pay-list-title {
  font-size: 14px;
  color: #4e5766;
  line-height: 20px;
  margin-bottom: 8px;
  padding-top: 15px;
}
.pay-list-box{
  max-height: 65vh;
  overflow: scroll;
}
.pay-list {
  padding: 0 12px;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  box-sizing: border-box;
}
.max-hei-3 {
  max-height: 170px;
  overflow: hidden;
}
.max-hei-50 {
  max-height: 50vh;
  overflow: scroll;
}
.look-more{
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #f2f3f5;
  height:44px;
  line-height: 42px;
  font-size: 14px;
color: #929BAB;
  img{
    width: 14px;
    height: 14px;
    transform: rotate(90deg);
  }
  .arrow-right{
    transform: rotate(270deg);
  }
}
</style>
