//*******java引入接口****** */
import { javabaseURL } from "@/api/anyURL";
import axios from "axios";
import { Message, MessageBox } from "element-ui";
import { toast } from "@/main.js";
const service = axios.create({
  baseURL: javabaseURL,
  headers: {
    "Content-Type": "application/json;",
  },
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    config.headers["token"] = token;
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== "000000") {
      const shield = [
        "600129", //请登录后进行支付
        "600130", //登录用户同分享清单用户不一致，请切换至清单用户登录
        "601228", //抱歉当前链接已失效，请联系业务员
        '601113',//连锁总部未入驻平台
        '601229',//清单链接已失效
      ];
      if (shield.includes(res.code)) {
      } else {
        toast.$children[0].show(res.message);
        // Message({
        //   message: res.message,
        //   type: "error",
        //   duration: 5 * 1000,
        // });
      }
    }
    return res;
  },
  (error) => {
    console.log("err" + error); // for debug
    // Message({
    //   message: '服务器响应失败！！，请联系管理员',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    // alert('服务器响应失败')
    toast.$children[0].show(res.message);
    // Message({
    //   message: res.message,
    //   type: "error",
    //   duration: 5 * 1000,
    // });
    // return Promise.reject(error);
  }
);

export default service;
