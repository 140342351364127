<template>
  <div class="shade" @click="cancel('closecover')">
    <div class="cvoer" @click.stop="">
      <div class="title" v-if="title">
        {{ title }}
      </div>
      <div class="mess" v-if="mess">
        <div class="">
          {{ mess }}
        </div>
      </div>
      <slot></slot>
      <div class="footer" v-if="operate" @click="cancel('closecover')">
        {{ operate }}
      </div>
    </div>
  </div>
</template>

<script>
import props from "./props.js";
export default {
  mixins: [props],
  name: "yjl-affirm",
  data() {
    return {};
  },
  methods: {
    cancel(val) {
      this.$emit("cancel");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.shade {
  background-color: rgba($color: #000000, $alpha: 0.45);
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000000;
  padding: 0 30px;
  box-sizing: border-box;
  .cvoer {
    background-color: #fff;
    border-radius: 16px;
    width: 100%;

    .title {
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      padding-top: 20px;
      color: #091229;
      font-weight: bold;
      padding-bottom: 12px;
    }
    .mess {
      line-height: 22px;
      padding: 0 16px 25px;
      color: #091229;
      font-size: 16px;
      display: flex;
      justify-content: center;
      div {
      }
    }
    .footer {
      color: #ff6633;
      line-height: 44px;
      text-align: center;
      font-size: 18px;
      border-top: 1px #eee solid;
    }
  }
}
</style>
